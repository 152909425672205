import { Link } from 'gatsby'
import React from 'react'
import Logo from '../../assets/svg/logo-stacked.svg'
import Cheers from '../../assets/svg/cheers.svg'

// import s from './footer.scss'

const Footer = () => (
	<footer className="footer">
		<div className="cheers">
			<Cheers />
		</div>
		<div className="footer__inner">
			<nav className="footer-nav">
				<Link className="footer-nav__item" to='/#about'>
					About
				</Link>
				<Link className="footer-nav__item" to='/#event'>
					Event
				</Link>
				{/* <Link className={s.footer-nav__item} to='/raffle'>
					Raffle
				</Link>
				<a className={s.footer-nav__item} href='/'>
					Donate
				</a> */}
			</nav>

			<div className="footer__logo">
				<Link to='/'>
					<Logo />
				</Link>
			</div>
		</div>
	</footer>
)

export default Footer
