import React, { createContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const OPTIONS_QUERY = graphql`
  query OptionsQuery {
    prismicOptions {
      data {
        set_raffle_live
        cart_instructions {
          html
        }
        thank_you_message {
          html
        }
      }
    }
  }
`;

const initialState = {
	isCartOpen: false,
	cartItems: [],
  raffleState: false
}

export const StoreContext = createContext()

export const StoreProvider = ({ children }) => {
  const {
    prismicOptions: {
      data: { set_raffle_live, cart_instructions, thank_you_message },
    },
  } = useStaticQuery(OPTIONS_QUERY);

	const [cartState, setCartState] = useState({
    isCartOpen: false,
    cartItems: [],
    raffleState: set_raffle_live,
    cart_instructions,
    thank_you_message,
  });
  

	return (
		<StoreContext.Provider value={[cartState, setCartState]}>
			{children}
		</StoreContext.Provider>
	)
}
