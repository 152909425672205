import { Link } from "gatsby";
import React, { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";
import Logo from "../../assets/svg/logo-stacked.svg";
import Bag from "../../assets/svg/bag.svg";

// import s from "./header.scss";

const Header = () => {
  const [cartState, setCartState] = useContext(StoreContext);

  const quantity = cartState.cartItems
    .map((item) => item.tickets)
    .reduce((total, amount) => total + amount, 0);

  const openCart = () => {
    setCartState({ ...cartState, isCartOpen: !cartState.isCartOpen });
  };

  return (
    <header className="header">
      {/* <div className={`header__cart `} onClick={openCart}>
        <Bag />
        <span>{quantity}</span>
      </div> */}
      <div className="header__inner">
        <div className="header__logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <nav className="main-nav">
          <div className={`main-nav__container main-nav__container--left`}>
            <Link className="main-nav__item" to="/#about">
              About
            </Link>
            <Link className="main-nav__item" to="/#event">
              Event
            </Link>
            {cartState.raffleState && (
              <Link className="main-nav__item" to="/raffle">
                Raffle
              </Link>
            )}
          </div>
          <div className={`main-nav__container main-nav__container--right`}>
            <Link className="main-nav__item" to="/goods">
              Goods
            </Link>
            <a
              href="https://buy.stripe.com/28obM0bH6gvc2aIbII"
              className="main-nav__item"
            >
              Donate
            </a>
            <Link className="main-nav__item nav-cart" to="/cart">
              <Bag
                className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                {quantity}
              </span>
              {/* <span className="sr-only">items in cart, view bag</span> */}
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
